
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

.smallicon {
  padding-top: 15px;
  padding-right: 5px;
}

.backdrop {
  position: fixed !important;
}

.spinner-pulse {
  position: fixed !important;
}

.tumua {
  padding: 10px;
}

a {
  color: #444444;
  outline: none;
}

a:hover {
  color: #b9912c;
  text-decoration: none;
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Nunito", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  transition: 0.5s;
}

.back-to-top i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #b9912c;
  color: #fff;
  transition: all 0.4s;
}

.back-to-top:hover {
  bottom: 19px;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 1000;
  padding: 0px 0;
}

#header .logo {
  font-size: 32px;
  width: auto;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
}

#header .logo a {
  color: #fff;
}

#header .logo img {
  max-height: 40px;
  margin-left: 0px;
}

#header.header-scrolled, #header.header-inner-pages {
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 12px 0;
}

#header.header-scrolled .logo a, #header.header-inner-pages .logo a {
  color: #493c3e;
}

@media (max-width: 768px) {
  #header .logo img {

    width: 180px;
  }
}

/*--------------------------------------------------------------
# Top Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.top-nav-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  float: right;
}

.top-nav-menu > ul {
  display: flex;
}

.top-nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  padding: 10px 2px 0px 0px;
}

.top-nav-menu span {
  display: block;
  position: relative;
  color: white;
  transition: 0.3s;
  font-size: 10px;
  padding: 0 0px;
  letter-spacing: 0.4px;
  font-family: "Poppins", sans-serif;
}

.top-nav-menu a {
  display: block;
  position: relative;
  color: #000000;
  font-weight: bold;
  transition: 0.3s;
  font-size: 14px;
  padding: 0 4px;
  letter-spacing: 0.4px;

}

.top-nav-menu .signing {
  color: #b9912c;
}

.top-nav-menu > ul > li > a:hover {
  color: #000000;
}

.cool-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: mediumblue;

}

.top-nav-menu > ul > li > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  color: #b9912c;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.top-nav-menu a:hover:before, .top-nav-menu li:hover > a:before, .top-nav-menu .active > a:before {
  visibility: visible;
  transform: scaleX(1);
}

.top-nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% + 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.top-nav-menu .drop-down:hover > ul {
  visibility: hidden;
}

.top-nav-menu .drop-down li {
  visibility: hidden;
}

#hero .belowImages img:hover {
  opacity: 0.7;
}

.header-right {
  position: relative
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu-mid ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu-mid > ul {
  display: inline-flex;
}

.nav-menu-mid > ul > li {
  position: relative;
  white-space: nowrap;
  font-weight: 600;
  padding: 10px 12px 10px 12px;
}

.nav-menu-mid a {
  display: block;
  position: relative;
  color: #000000;
  transition: 0.3s;
  font-size: 15px;
  padding: 0 4px;
  letter-spacing: 0.4px;
  font-family: "Poppins", sans-serif;
}

.nav-menu-mid a:hover {
  color: #b9912c;
}

.nav-menu-mid > ul > li > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #b9912c;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.nav-menu-mid a:hover:before, .nav-menu-mid li:hover > a:before, .nav-menu-mid .active > a:before {
  visibility: visible;
  transform: scaleX(1);
}

.nav-menu-mid .drop-down ul {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% + 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.nav-menu-mid .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu-mid .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu-mid .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #493c3e;
}

.nav-menu-mid .drop-down ul a:hover, .nav-menu-mid .drop-down ul .active > a, .nav-menu-mid .drop-down ul li:hover > a {
  color: #b9912c;
}


.nav-menu-mid .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu-mid .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu-mid .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu-mid .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu-mid .drop-down .drop-down > a:after {
  content: "\eaa0";
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

.header-scrolled .nav-menu-mid > ul > li > a, .header-inner-pages .nav-menu-mid > ul > li > a {
  color: #493c3e;
}

@media (max-width: 992px) {
  .nav-menu-mid .drop-down .drop-down ul {
    left: -90%;
  }
  .nav-menu-mid .drop-down .drop-down:hover > ul {
    left: -100%;
  }
  .nav-menu-mid .drop-down .drop-down > a:after {
    content: "\ea9d";
  }
}


.nav-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul {
  display: flex;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  font-weight: 600;
  padding: 10px 0 10px 25px;
}

.nav-menu a {
  display: block;
  position: relative;
  color: #000000;
  transition: 0.3s;
  font-size: 15px;
  padding: 0 4px;
  letter-spacing: 0.4px;
  font-family: "Poppins", sans-serif;
}

.nav-menu a:hover {
  color: #b9912c;
}

.nav-menu > ul > li > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #b9912c;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.nav-menu a:hover:before, .nav-menu li:hover > a:before, .nav-menu .active > a:before {
  visibility: visible;
  transform: scaleX(1);
}

.nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% + 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #493c3e;
}

.nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a {
  color: #b9912c;
}

.nav-menu .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
  content: "\eaa0";
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

.header-scrolled .nav-menu > ul > li > a, .header-inner-pages .nav-menu > ul > li > a {
  color: #493c3e;
}

@media (max-width: 2000px) {
  .nav-menu .drop-down .drop-down ul {
    left: -90%;
  }
  .nav-menu .drop-down .drop-down:hover > ul {
    left: -100%;
  }
  .nav-menu .drop-down .drop-down > a:after {
    content: "\ea9d";
  }
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  color: #b9912c;
}

.mobile-nav {
  position: fixed;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #493c3e;
  padding: 10px 20px;
  font-weight: 500;
  outline: none;
}

.mobile-nav a:hover, .mobile-nav .active > a, .mobile-nav li:hover > a {
  color: #b9912c;
  text-decoration: none;
}

.mobile-nav .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobile-nav .active.drop-down > a:after {
  content: "\eaa1";
}

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(45, 37, 38, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background: url("assets/img2/anasayfa.jpg") top center;
  background-size: cover;
  position: relative;
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .hero-container {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 15px;
  margin-top: 180px;
}

#hero h3 {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 26px;
  padding: 10px 30px;
  margin-bottom: 30px;
  border-radius: 50px;
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 100px;
  text-transform: uppercase;
  color: #000000;
}

#hero .belowImages img {
  width: 140px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 30px
}

#hero h2 {
  color: #eee;
  margin-bottom: 40px;
  font-size: 24px;
}

#hero .btn-get-started {
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 30px 9px 30px;
  border-radius: 50px;
  transition: 0.5s;
  border: 2px solid #fff;
  color: #fff;
}

/*#hero input[type="search"] {*/
/*  width: 650px;*/
/*}*/
#hero .btn-get-started:hover {
  background: #b9912c;
  border: 2px solid #b9912c;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero h3 {
    font-size: 22px;
  }
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  /*#hero input[type="search"] {*/
  /*  width: 90% ;*/
  /*}*/
  #hero .belowImages img {
    width: 80px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 150vh;
  }
}


@media (max-width: 400px) {
  #hero ul li a {
    font-size: 10px;
  }
  #header .logo img {

    width: 120px;
  }
  #hero .belowImages img {
    width: 60px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 20px
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  background: #FBFBFB;
}

.section-bg {
  background-color: white;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title-index {
  text-align: left;
}

.section-title h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  line-height: 1;
  margin: 0;
  background: #fdeff2;
  color: #b9912c;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
}

.section-title h3 {
  margin: 15px 0 0 0;
  font-size: 32px;
  font-weight: 700;
}

.section-title h3 span {
  color: #b9912c;
}

.section-title p {
  margin: 15px auto 0 auto;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .section-title p {
    width: 50%;
  }
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-left: 28px;
  position: relative;
}

.about .content ul li + li {
  margin-top: 10px;
}

.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #b9912c;
  line-height: 1;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .btn-learn-more {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.3s;
  line-height: 1;
  color: #b9912c;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid #b9912c;
}

.about .content .btn-learn-more:hover {
  background: #b9912c;
  color: #fff;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services {
  padding-bottom: 30px;
}

.services .icon-box {
  padding: 60px 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 2px 0 35px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  text-align: center;
}

.services .icon-box::before {
  content: '';
  position: absolute;
  background: #fdeff2;
  right: 0;
  left: 0;
  bottom: 0;
  top: 100%;
  transition: all 0.4s;
  z-index: -1;
}

.services .icon-box:hover::before {
  background: #b9912c;
  top: 0;
  border-radius: 0px;
}

.services .icon {
  margin-bottom: 15px;
}

.services .icon i {
  font-size: 48px;
  line-height: 1;
  color: #b9912c;
  transition: all 0.3s ease-in-out;
}

.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .title a {
  color: #111;
}

.services .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.services .icon-box:hover .title a, .services .icon-box:hover .description {
  color: #fff;
}

.services .icon-box:hover .icon i {
  color: #fff;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features {
  padding-top: 0;
}

.features .icon-box {
  display: flex;
  align-items: center;
  padding: 20px;
  transition: ease-in-out 0.3s;
  box-shadow: 2px 0 35px 0 rgba(68, 88, 144, 0.12);
}

.features .icon-box i {
  font-size: 32px;
  padding-right: 10px;
  line-height: 1;
}

.features .icon-box h3 {
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 16px;
}

.features .icon-box h3 a {
  color: #493c3e;
  transition: ease-in-out 0.3s;
}

.features .icon-box:hover a {
  color: #b9912c;
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  background: linear-gradient(rgba(2, 2, 2, 0.5), rgba(0, 0, 0, 0.8)), url("assets/img/cta-bg.jpg") center center;
  background-size: cover;
  padding: 60px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}

.cta .cta-btn {
  font-family: "Nunito", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 25px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: #b9912c;
  border: 2px solid #b9912c;
}

@media (min-width: 1024px) {
  .cta {
    background-attachment: fixed;
  }
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 7px 17px 9px 17px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #444444;
  margin: 0 3px 10px 3px;
  transition: all ease-in-out 0.3s;
  background: #ede9e9;
  border-radius: 50px;
}

.portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
  color: #fff;
  background: #b9912c;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-item {
  margin-bottom: 30px;
  overflow: hidden;
}

.portfolio .portfolio-item img {
  position: relative;
  top: 0;
  transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: -50px;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: #b9912c;
  padding: 15px 20px;
}

.portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-item .portfolio-info p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link, .portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 50px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: white;
  transition: ease-in-out 0.3s;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover, .portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: #f7c2cc;
}

.portfolio .portfolio-item .portfolio-info .details-link {
  right: 15px;
}

.portfolio .portfolio-item:hover img {
  top: -30px;
}

.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  bottom: 0;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
  padding: 20px;
  background: #f9f9f9;
  text-align: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  border: 2px solid #f9f9f9;
}

.pricing .box h3 {
  font-weight: 400;
  padding: 15px;
  margin-top: 15px;
  font-size: 20px;
  font-weight: 600;
  color: #493c3e;
}

.pricing .box h4 {
  font-size: 42px;
  color: #b9912c;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 20px;
}

.pricing .box h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing .box h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing .box ul {
  padding: 0;
  list-style: none;
  color: #493c3e;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing .box ul li {
  padding-bottom: 16px;
}

.pricing .box ul i {
  color: #b9912c;
  font-size: 18px;
  padding-right: 4px;
}

.pricing .box ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .box .btn-wrap {
  padding: 15px;
  text-align: center;
}

.pricing .box .btn-buy {
  display: inline-block;
  padding: 8px 40px 10px 40px;
  border-radius: 50px;
  border: 2px solid #b9912c;
  color: #b9912c;
  font-size: 14px;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  transition: 0.3s;
}

.pricing .box .btn-buy:hover {
  background: #b9912c;
  color: #fff;
}

.pricing .recommended {
  border-color: #b9912c;
}

.pricing .recommended .btn-buy {
  background: #b9912c;
  color: #fff;
}

.pricing .recommended .btn-buy:hover {
  background: #d91e42;
  border-color: #d91e42;
}

.pricing .recommended-badge {
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  font-size: 13px;
  padding: 3px 25px 6px 25px;
  background: #fce6ea;
  color: #b9912c;
  border-radius: 50px;
}

/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq {
  padding: 60px 0;
}

.faq .faq-list {
  padding: 0;
  list-style: none;
}

.faq .faq-list li {
  padding: 0 0 20px 25px;
}

.faq .faq-list a {
  display: block;
  position: relative;
  color: #b9912c;
  font-size: 18px;
  font-weight: 500;
}

.faq .faq-list i {
  font-size: 18px;
  position: absolute;
  left: -25px;
  top: 6px;
}

.faq .faq-list p {
  margin-bottom: 20px;
  font-size: 15px;
}

.faq .faq-list a.collapse {
  color: #b9912c;
}

.faq .faq-list a.collapsed {
  color: #343a40;
}

.faq .faq-list a.collapsed:hover {
  color: #b9912c;
}

.faq .faq-list a.collapsed i::before {
  content: "\eab2" !important;
}

@media (min-width: 1280px) {
  .faq .container {
    padding: 0 120px;
  }
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team-paddding {
  padding: 0px;
}

.team {
  background: #f8f9fa;
  margin-bottom: 10px;
}

.layoutplan {
  border-radius: 20px;
  width: 100%;
}

/* Thick red border */
hr.horizontalline {
  border-top: 2.3px solid #b9912c;
  margin-top: -0.1rem;
}

.margin-zero {
  margin-right: 0px;
  margin-left: 0px;
}

.team .member {
  min-height: 230px;
  max-height: 230px;
  min-width: 170px;
  max-width: 170px;

  overflow: hidden;
  text-align: center;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin: 0px 10px;
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;

}

.member3 {
  width: 100%;
  overflow: hidden;
  text-align: center;
  border-radius: 15px;
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  display: inline-block;
  position: relative;
}

.bbc {
  border-bottom: 10px solid #b9912c;
  min-height: 155px;
  padding: 10px;
}

.bbc p {
  min-height: 58px;
}

.greycolor {
  color: #e9ecef;
}

.blackcolor {
  color: #444444;
}

.img-fluid2 {
  width: 175px;
  min-height: 162px;
  max-height: 162px;
  margin-left: -10px;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.width20 {
  width: 20%;
}

.team .member2 {
  margin-bottom: 20px;
  margin-left: 15px;
  overflow: hidden;
  border-radius: 10px;
  font-family: BlinkMacSystemFont;
  max-width: 210px;
  background: #f8f6f6;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}

.home-name {
  font-size: 12px;
  font-weight: bold;
  position: page;
  color: #1d1d1d;
}

.home-description {
  font-size: 10px;
  color: #1d1d1d;
}

.home-description p {
  margin-bottom: 0rem;
}

.home-price {
  font-size: 12px;
  color: #b9912c;
  font-weight: bold;
}

.slider-wrap {
  position: relative;
  color: #1d1d1d
}

.sin-pro {
  margin-bottom: 50px;
  padding-bottom: 15px;
}

.sin-pro-img-action {
  position: relative;
}

.fix {
  overflow: hidden
}

.product-slider .sin-pro, .product-slider-2 .sin-pro {
  margin-bottom: 0;
  padding-bottom: 0;
}

.margin-bottom-90 {
  margin-bottom: 90px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.nivo-caption {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  height: 100%;
  opacity: 1;
  padding: 0;
}

.member-info {
  padding: 8px 8px;
}

.member-img {
  position: relative;
  overflow: hidden;
}

.team .member2 .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
  background: rgba(255, 255, 255, 0.85);
}

.team .member .social a {
  transition: color 0.3s;
  color: #493c3e;
  margin: 0 10px;
  padding-top: 8px;
  display: inline-block;
}

.team .member .social a:hover {
  color: #b9912c;
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}


.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 14px;
  color: #493c3e;
  text-align: center;
  min-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team .member .member-info span {
  text-align: left;
  display: block;
  font-size: 10px;
  margin-bottom: 5px;
  font-weight: 400;
  text-align: center;
  color: black;
  min-height: 40px;
  max-height: 40px;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team .member .member-info .country-city {
  text-align: left;
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 400;

  color: #aaaaaa;
  min-height: 54px;
  max-height: 54px;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1250px) {
  .team .member .member-info h4 {
    font-size: 12px;
  }
  .team .member .member-info p {
    font-size: 10px;
    margin-bottom: 0px;
  }
  .team .member .member-info span {

    font-size: 10px;
    margin-bottom: 5px;
    font-weight: 400;
    min-height: 35px;
    color: #aaaaaa;
  }
}

@media (max-width: 450px) {
  .team .member .member-info h4 {
    font-size: 12px;
  }
  .team .member .member-info p {
    font-size: 12px;
    margin-bottom: 0px;
  }
  .team .member .member-info span {

    font-size: 10px;
    margin-bottom: 5px;
    font-weight: 400;
    min-height: 30px;
    color: #aaaaaa;
  }

}

.team .member .member-info p {
  text-align: center;
  display: block;
  font-size: 10px;
  font-weight: 700;
  color: #b9912c;
  margin-bottom: 0px;

}

.team .member:hover .social {
  opacity: 1;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  width: 100%;
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  color: #b9912c;
  float: left;
  width: 44px;
  height: 44px;
  background: #fdeff2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #493c3e;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #816a6e;
}

.contact .info .email, .contact .info .phone {
  margin-top: 40px;
}

.contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
  background: #b9912c;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
  border-color: #b9912c;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  background: #b9912c;
  border: 0;
  padding: 10px 28px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #d01d3f;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #f6f4f4;
  margin-top: 67px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 50px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #655356;
  content: "/";
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-container {
  position: relative;
}

.portfolio-details .portfolio-details-carousel {
  position: relative;
  z-index: 1;
}

.portfolio-details .portfolio-details-carousel .owl-nav, .portfolio-details .portfolio-details-carousel .owl-dots {
  margin-top: 5px;
  text-align: left;
}

.portfolio-details .portfolio-details-carousel .owl-dot {
  display: inline-block;
  margin: 0 10px 0 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd !important;
}

.portfolio-details .portfolio-details-carousel .owl-dot.active {
  background-color: #b9912c !important;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  position: absolute;
  right: 0;
  bottom: -70px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 50px;
}

.portfolio-details .portfolio-description h2 {
  width: 50%;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0 0 0 0;
}

@media (max-width: 768px) {
  .portfolio-details .portfolio-info {
    position: static;
    margin-top: 30px;
  }
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
  padding: 40px 0 20px 0;
}

.blog .entry {
  padding: 20px;
  margin-bottom: 60px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
  max-height: 400px;
  margin: -20px -20px 20px -20px;
  overflow: hidden;
}

.blog .entry .entry-title {
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
  color: #493c3e;
  transition: 0.3s;
}

.blog .entry .entry-title a:hover {
  color: #b9912c;
}

.blog .entry .entry-meta {
  color: #9a8487;
}

.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.blog .entry .entry-meta ul li {
  margin-bottom: 15px;
}

.blog .entry .entry-meta ul li + li {
  padding-left: 15px;
}

.blog .entry .entry-meta i {
  font-size: 14px;
  padding-right: 4px;
}

.blog .entry .entry-meta a {
  color: #9a8487;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .entry .entry-content p {
  line-height: 24px;
  font-size: 15px;
}

.blog .entry .entry-content .read-more {
  -moz-text-align-last: right;
  text-align-last: right;
}

.blog .entry .entry-content .read-more a {
  display: inline-block;
  background: #b9912c;
  color: #fff;
  padding: 5px 20px 7px 20px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 50px;
}

.blog .entry .entry-content .read-more a:hover {
  background: #d01d3f;
}

.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
  color: #444444;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .entry .entry-content blockquote .quote-left {
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 36px;
  color: #e7e7e7;
}

.blog .entry .entry-content blockquote .quote-right {
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 36px;
  color: #e7e7e7;
}

.blog .entry .entry-content blockquote::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #493c3e;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
  color: #9a8487;
  display: inline;
}

.blog .entry .entry-footer a {
  color: #c3b6b8;
  transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
  color: #b9912c;
}

.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .entry .entry-footer .cats li {
  display: inline-block;
}

.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .entry .entry-footer .tags li {
  display: inline-block;
}

.blog .entry .entry-footer .tags li + li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}

.blog .entry .entry-footer .share {
  font-size: 16px;
}

.blog .entry .entry-footer .share i {
  padding-left: 5px;
}

.blog .entry-single {
  margin-bottom: 30px;
}

.blog .entry-single .entry-title {
  font-size: 36px;
  line-height: 42px;
}

.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
  width: 120px;
}

.blog .blog-author h4 {
  margin-left: 140px;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
}

.blog .blog-author .social-links {
  margin: 0 0 5px 140px;
}

.blog .blog-author .social-links a {
  color: #b1a0a3;
}

.blog .blog-author p {
  margin-left: 140px;
  font-style: italic;
  color: #b7b7b7;
}

.blog .blog-comments {
  margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
  font-weight: bold;
}

.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .blog-comments .comment .comment-img {
  width: 50px;
}

.blog .blog-comments .comment h5 {
  margin-left: 65px;
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #444444;
  transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
  color: #b9912c;
}

.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #493c3e;
}

.blog .blog-comments .comment time {
  margin-left: 65px;
  display: block;
  font-size: 14px;
  color: #b1a0a3;
  margin-bottom: 5px;
}

.blog .blog-comments .comment p {
  margin-left: 65px;
}

.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .blog-comments .reply-form p {
  font-size: 14px;
}

.blog .blog-comments .reply-form input {
  border-radius: 0;
  padding: 20px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #f096a7;
}

.blog .blog-comments .reply-form textarea {
  border-radius: 0;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #f096a7;
}

.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
  padding: 10px 20px;
  border: 0;
  border-radius: 50px;
  background-color: #493c3e;
}

.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #b9912c;
}

.blog .blog-pagination {
  color: #816a6e;
}

.blog .blog-pagination ul {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.blog .blog-pagination li {
  border: 1px solid white;
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li.active {
  background: white;
}

.blog .blog-pagination li a {
  color: #aaaaaa;
  padding: 7px 16px;
  display: inline-block;
}

.blog .blog-pagination li.active, .blog .blog-pagination li:hover {
  background: #b9912c;
  border: 1px solid #b9912c;
}

.blog .blog-pagination li.active a, .blog .blog-pagination li:hover a {
  color: #fff;
}

.blog .blog-pagination li.disabled {
  background: #fff;
  border: 1px solid white;
}

.blog .blog-pagination li.disabled i {
  color: #f1f1f1;
  padding: 10px 16px;
  display: inline-block;
}

.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #493c3e;
  position: relative;
}

.blog .sidebar .sidebar-item {
  margin-bottom: 30px;
}

.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid #d3cacb;
  padding: 3px 10px;
  position: relative;
  border-radius: 50px;
}

.blog .sidebar .search-form form input[type="text"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 42px);
}

.blog .sidebar .search-form form button {
  position: absolute;
  border-radius: 50px;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  margin: -1px;
  background: #b9912c;
  color: #fff;
  transition: 0.3s;
}

.blog .sidebar .search-form form button:hover {
  background: #d01d3f;
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li + li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: #655356;
}

.blog .sidebar .categories ul a:hover {
  color: #b9912c;
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #b1a0a3;
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item + .post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}

.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
  color: #110e0e;
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: #b9912c;
}

.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #b1a0a3;
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #8e767a;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid #ede9e9;
  display: inline-block;
  border-radius: 50px;
  transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid #b9912c;
  background: #b9912c;
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #dfd8d9;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  color: #1a1a1a;
  font-size: 14px;
  background: #1d1d1d;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.vl {
  border-left: 2px solid #b9912c;
  position: sticky;
  top: 0;
}

.vl2 {
  border-left: 2px solid #b9912c;
  position: sticky;
  top: 0;
}

#footer .footer-top {
  padding: 30px 0 0;
  background: #343333;

}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h4 {
  font-size: 22px;
  margin: 0 0 30px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Nunito", sans-serif;
  color: #ffffff;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fcfcfc;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top h3 {
  font-weight: bold;
  color: #fcfcfc;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  font-family: "Poppins", sans-serif;
  text-align: center;
}

#footer .footer-social {
  text-align: center;
  margin: 5px;
}

#footer h4 {
  text-align: center;
  cursor: grab;
  padding-top: 10px;
}

#footer h3 {
  text-align: center;
}

#footer ul {
  text-align: center;
  list-style-type: none;
  padding-inline-start: 0px
}

#footer ul li a {
  text-align: center;
  display: inline;
  align-items: center;
  color: #FFFFFF;
  cursor: pointer;

}

#footer ul li a:hover {
  transition: 0.3s;
  color: #b9912c;
}

.footer-logo {
  margin-top: 60px;
  padding-bottom: 20px;
}

#footer .footer-newsletter {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

#footer .footer-newsletter h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fafafa;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
  text-align: center;
  border: 1px solid #b9912c;
}

#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 15px;
  padding: 0 22px;
  background: #b9912c;
  color: #b9912c;
  transition: 0.3s;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type="submit"]:hover {
  background: #efe9e9;
}

#footer .credits {
  padding-top: 5px;
  font-size: 13px;
  color: #444444;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #b9912c;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: #d01d3f;
  color: #fff;
  text-decoration: none;
}

.center-footer {
  text-align: center;
}


/* copyright */
.copyright {
  text-align: center;
  color: #ffffff;
}


/* copyright */
/* searchButton*/
.searchButton form {
  margin-top: 2px;
  background: #fff;
  padding: 2px 2px;
  position: relative;
  border-radius: 8px;
  text-align: left;
  border: 3px solid #b9912c;
}

.searchButton form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

.searchButton form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 0px;

  font-size: 15px;
  padding: 0 0px;

  color: #fff;
  transition: 0.3s;
  border-radius: 6px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.searchButton form input[type="search"] {
  outline: none;
  width: 100%;
  border: 0;
  text-indent: 20px;
}

.searchButton form input[type="submit"]:hover {
  background: #000000;
}

/*input[type="text"] {*/
/*  width: 100%;*/
/*  border: 2px solid #b9912c;*/
/*  border-radius: 4px;*/
/*  margin: 8px 0;*/
/*  outline: none;*/
/*  padding: 8px;*/
/*  box-sizing: border-box;*/
/*  transition: 0.3s;*/
/*}*/
.input-group {
  width: 600px;
  border-radius: 6px;
  border: 2px solid #b9912c;
}

.input-group-append {
  border-radius: 5px;
}

#hero input[type="text"]:focus {
  border-color: #b9912c;
  box-shadow: 0 0 10px 0 #b9912c;
}

.copyright {
  text-align: center;
  color: #ffffff;
}

@media (max-width: 992px) {
  #hero .input-group {
    width: 80%;
  }
}

#first section {
  height: 100vh;
  width: 100%;
  display: table;

}

/*
.scroll-down {
  cursor: pointer;
  opacity: 1;
  -webkit-transition: all .5s ease-in 3s;
  transition: all .5s ease-in 3s;
}*/


.inputWithIcon input[type="text"] {
  padding-left: 40px;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon i {
  position: absolute;
  left: 0;
  top: 8px;
  padding: 9px 8px;
  color: #b9912c;
  transition: 0.3s;
}

.inputWithIcon input[type="text"]:focus + i {
  color: #b9912c;
}

.inputWithIcon.inputIconBg i {
  background-color: #b9912c;
  color: #fff;
  padding: 9px 4px;
  border-radius: 4px 0 0 4px;
}

.inputWithIcon.inputIconBg input[type="text"]:focus + i {
  color: #fff;
  background-color: #b9912c;
}

.page-wrapper {
  min-height: 100%;
}

.page-wrapper a:hover {
  color: #b9912c;
}

/* POST SLIDER */

.post-slider {
  position: relative;
  height: fit-content;
}

.post-slider .slider-title {
  text-align: center;
  margin: 30px auto;
}

.post-slider .next {
  position: absolute;
  top: 40%;
  right: 30px;
  font-size: 22px;
  color: #b9912c;
  cursor: pointer;
}

.post-slider .prev {
  position: absolute;
  top: 40%;
  left: 30px;
  font-size: 22px;
  color: #b9912c;
  cursor: pointer;
}

.post-slider .next2 {
  position: absolute;
  top: 40%;
  right: 30px;
  font-size: 22px;
  color: #b9912c;
  cursor: pointer;
}

.post-slider .prev2 {
  position: absolute;
  top: 40%;
  left: 30px;
  font-size: 22px;
  color: #b9912c;
  cursor: pointer;
}

.post-slider .next4 {
  position: absolute;
  top: 40%;
  right: 30px;
  font-size: 22px;
  color: #b9912c;
  cursor: pointer;
}

.post-slider .prev4 {
  position: absolute;
  top: 40%;
  left: 30px;
  font-size: 22px;
  color: #b9912c;
  cursor: pointer;
}

.post-slider .post-wrapper {
  width: 84%;
  height: 250px;
  margin: 0px auto;
  overflow: hidden;
  padding: 10px 0px 10px 0px;
}

.post-slider .post-wrapper .post {
  width: 150px;
  height: 200px;
  margin: 0px 10px;
  display: inline-block;
  position: relative;
  background: white;
  border-radius: 15px;
  box-shadow: 0.3rem 0.3rem 0.5rem -0.3rem rgb(156, 145, 145);
}

.post-info {
  height: 50px;
  padding: 0px 5px;
}

.post-info1 {
  height: 150px;
  width: 100%;
  margin-left: 10px;
  border: 1px solid #a7a4a4;
}

.post-slider .post-wrapper .post .slider-image {
  outline: none;
  width: 100%;
  height: 200px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}


.post-slider .post-wrapper .post .slider-image1 {
  outline: none;
  width: 100%;
  height: 100px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.post-slider .post-wrapper2 {
  width: 84%;
  height: 250px;
  margin: 0px auto;
  overflow: hidden;
  padding: 10px 0px 10px 0px;
}


.post-slider .post-wrapper4 {
  width: 84%;
  height: 250px;
  margin: 0px auto;
  overflow: hidden;
  padding: 10px 0px 10px 0px;
}


.title-img {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  color: #b9912c;

}

.home-list {
  background: #f8f9fa;
  padding-top: 130px;
  padding-bottom: 40px;
}


.search-property .find-home-cagtegory {
  padding-left: 10px;
  padding-right: 10px;
  height: fit-content;
}

.pb-20 {
  padding-bottom: 20px;
}

.find-home-item > button {
  padding: 0 15px;
}

.find-home-bottom .find-home-item > button {
  margin-top: 20px;
}

.find-home-item {
  text-align: center;
}

.sidebar-item-selection {
  width: 100%;
  border-radius: 5px;
  border: 2px solid #b9912c;
  padding: 3px;
  text-indent: 5px;
  background: #ffffff;
}

.sidebar-item-selection3 {
  width: 200px;
  text-align: center;
  display: inline;
  border-radius: 10px;
  height: 35px;
  border: 3px solid #b9912c;
  padding: 5px;
  background: #f8f9fa;
}

.sidebar-item-selection [type="text"]:focus {
  border-color: #b9912c;
  box-shadow: 0 0 10px 0 #b9912c;
}

.header-item-selection {
  width: 50%;
  border-radius: 5px;
  border: 2px solid #b9912c;
  padding: 5px;
  background: #f8f9fa;
}

.header-item-selection [type="text"]:focus {
  border-color: #b9912c;
  box-shadow: 0 0 10px 0 #b9912c;
}

.side-search-bar {
  background: #e9ecef;
  border-radius: 10px;
  height: fit-content;
}

.side-search-bar-top {
  background: #e9ecef;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: fit-content;
}

.side-search-bar-center {
  background: #e9ecef;
  height: fit-content;
}

.side-search-bar-bottom {
  background: #e9ecef;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: fit-content;
}

.search-property h4 {
  padding-bottom: 5px;
  padding-top: 12px;
  color: #222222;
  font-weight: 700;
  font-size: 20px;
  float: left;
}

.search-property h5 {
  padding-bottom: 5px;
  padding-top: 12px;
  color: #222222;
  font-weight: 400;
  float: right;
}

.currency-selection {
  margin-bottom: 20px;
  border-radius: 5px;
  border: 2px solid #b9912c;
  min-width: 50px;
  color: black;
  background: #f8f9fa;
  text-align: center;

}


.input-left-sidebar {
  border-radius: 5px;
  border: 2px solid #b9912c;
  min-width: 50px;
  color: black;
  background: #f8f9fa;
  width: 90px;
  padding: 5px;
  margin-bottom: 20px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.bordereda {
  border: 2px solid #b9912c;
  border-radius: 12px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 700;
}

.bordereda3 {
  border: 3px solid #b9912c;
  border-radius: 8px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 700;
  font-size: 10px;
  color: #b9912c;
  margin-top: 3px;
}

.input-phone-btn-sidebar {
  margin-top: 5px;
  border: 3px solid #b9912c;
  border-radius: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 16px;
  padding-right: 2px;
  padding-left: 2px;
  width: 175px;
  cursor: alias;
}

.input-btn-sidebar {
  margin-top: 5px;
  border: 3px solid #b9912c;
  border-radius: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 16px;
  padding-right: 2px;
  padding-left: 2px;
  width: 175px;
  outline: none;
}

button:focus {
  outline: none;
}

.input-btn-sidebar:hover {
  border: 3px solid black;
  background: #000000;
  color: white;
  outline: none;
}


.company-logo-sidebar {
  background: white;
  height: 100px;
  width: 100px;
  object-fit: contain;
  border-radius: 50px;
  margin-bottom: 15px;
  border: 2px solid #b9912c;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 1);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 1);
}

.company-name-sidebar {
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
}

.colorwhite {
  color: white;
}

.colorwhitehover {
  color: white;
}

.colorwhitehover:hover {
  color: white;
}

.bgwhite {
  background: #ffffff;
}

.bggrey {
  background: #343333;
}

.currency-selection:hover {
  color: #ffffff;
  background: #000000;
  border: 2px solid #000000;
}

.currency-selection:active {
  color: #ffffff;
  background: #000000;
}

.currency-selection:focus {
  color: #ffffff;
  background: #000000;
}

.a-sidebar {
  color: #222222;
  text-decoration: underline;
  font-size: 20px;
}

.search-btn-sidebar {
  margin-top: 30px;
  width: 100%;
  border: 3px solid #b9912c;
  border-radius: 10px;
  color: white;
  font-weight: 700;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 24px;
}

.search-btn-sidebar:hover {
  border: 3px solid black;
  background: #000000;
  color: white;
}

.search-btn-sidebar-success {
  margin-top: 30px;
  width: 50%;
  border: 3px solid #b9912c;
  border-radius: 10px;
  color: white;
  font-weight: 400;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 16px;
}

.search-btn-sidebar-success:hover {
  border: 3px solid #343333;
  color: #e0a800;

}

.find-home-cagtegory input[type="text"]:focus {
  border-color: #b9912c;
  box-shadow: 0 0 10px 0 #b9912c;
}

.header-line {
  border-bottom: 2px solid #b9912c;
  margin-bottom: 15px;
}

.header-line .fa-chevron-right:before {
  margin-left: 3px;
  color: #b9912c;
  font-size: 14px;
}

.fa-home:before {
  color: #b9912c;
}

.colorful {
  color: #b9912c;
}

.header-line h5 {
  color: #222222;
  margin-bottom: 0rem;
  font-weight: 600;
  line-height: 1;
  font-size: 1rem;
}

.header-line h6 {
  color: #222222;
  margin-top: 2px;
  font-weight: 400;
  line-height: 1;
  font-size: 0.8rem;

}

.mb-0 {
  margin-bottom: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-2 {
  margin-top: 2px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pright-5 {
  padding-right: 5px;
}

.pright-10 {
  padding-right: 10px;
}

.prleft-5 {
  padding-left: 5px;
}

.prleft-10 {
  padding-left: 10px;
}

.padding-zero {
  padding-right: 0px;
  padding-left: 0px;
}

.padding-five {
  padding-right: 5px;
  padding-left: 5px;
}

.padding-ten {
  padding-right: 10px;
  padding-left: 10px;
}

@media (max-width: 770px) {
  #footer .lineBilow {
    border-bottom: 1px solid #b9912c;
    padding-top: 10px;

  }
  #footer .vl2 {
    border-left: 0px;
  }
  .header-item-selection {
    width: 100%;
  }
}

.header-line .bordereda {
  font-size: 8px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.section-background {
  background: white;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);

}

.fullheightwidth {
  width: 100%;
  height: 100%;
}

.text700 {
  font-weight: 700;
}

.progress-bar-warning {
  border-radius: 8px;
  text-align: center;
  background-color: #b9912c;
}

.width50 {
  width: 50%;
}

.text400 {
  font-weight: 400;
}

.small-size-green {
  font-size: 10px;
  color: #8dde10;
  padding: 5px;
}

.small-size {
  font-size: 10px;
  color: #C4C3C3;
  padding: 5px;
}

.display-flex {
  display: flex;
}

.font-xxs {
  font-size: 10px;
  padding-left: 5px;
  text-align: left;
}

.font-xs {
  font-size: 14px;
  text-align: left;
}

.font-s {
  font-size: 14px;
  text-align: left;
}

.sidebar-image-album .col-md-6 {
  padding-left: 10px;
  padding-right: 10px;
}

.bs-example {
  margin: 20px;
}


.input-btn-sidebar-reports {
  margin-top: 5px;
  border: 3px solid #b9912c;
  border-radius: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 16px;
  padding-right: 2px;
  padding-left: 2px;
  text-align: left;
  width: 90%;

}

.input-btn-sidebar-reports:focus {
  outline: none;

}

.input-btn-sidebar-reports:active {
  transform: scale(0.99);
}

.single-property-tab-content {
  border: 1px solid #ddd;
  margin-bottom: 30px;
  overflow: hidden;
  background-color: white;
  padding-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding-left: 0;
  padding-right: 0;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: #b9912c;
  color: white;
  font-weight: 700;
}

#single-property-map {
  width: 100%;
  height: 265px;
}

#single-property-street-map {
  width: 100%;
  height: 265px;
}

#thirtysix-single-property-map {
  width: 100%;
  height: 265px;
}

.single-property-tab-content ul li {
  width: 25%;
}

.single-property-tab-content-three ul li {
  width: 33.3333%;
}

.single-property-tab-content-three {
  border: 1px solid #ddd;
  margin-bottom: 30px;
  overflow: hidden;
  background-color: white;
  padding-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding-left: 0;
  padding-right: 0;
}

.single-prop-left-border {
  border-top-left-radius: 13px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
}

.single-prop-right-border {
  border-top-right-radius: 13px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
}

.single-prop-mid-border {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
}

.nav-tabs .single-prop-left-border .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: .75rem;
  border-top-right-radius: 0rem;
}

.nav-tabs .single-prop-right-border .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0.75rem;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
select,
.uneditable-input:focus {
  border-color: #b9912c;
  box-shadow: 0 0.2px 0.2px #b9912c inset, 0 0 1px #b9912c;
  outline: 0 none;
}

.product1 img {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 50px;
  width: 100%;
}

.product2 {
  margin: 10px;
  padding: 2px;
  position: relative;
  text-align: center;
}

.slider-nav {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  height: 250px;

  overflow: hidden;
  padding: 10px 0px 10px 0px;

}

.slider-nav .next5 {
  position: absolute;
  top: 40%;
  right: 30px;
  font-size: 22px;
  color: #b9912c;
  cursor: pointer;
}

.slider-nav .prev5 {
  position: absolute;
  top: 40%;
  left: 30px;
  font-size: 22px;
  color: #b9912c;
  cursor: pointer;
}

@media (max-width: 900px) {
  .product1 img {
    width: 50%;
  }
  .product-nav {
    width: auto;
  }

}

.album-container {
  position: relative;
  width: 100%;
}

.album-container .album-slider-img {
  width: 100%;
  height: auto;
}

.album-container .company-logo {
  background: white;
  position: absolute;
  top: 25%;
  left: 6%;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  object-fit: contain;
  transform: translate(-50%, -50%);
  border: none;
  cursor: pointer;
  text-align: center;
  z-index: 999;
}

.album-container .next {
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translate(-50%, -50%);
  height: 30px;
  color: white;
  font-size: 36px;
  border: none;
  cursor: pointer;
  text-align: center;
  z-index: 9999;
}

.album-container .prev {
  position: absolute;
  top: 50%;
  left: 4%;
  transform: translate(-50%, -50%);
  height: 30px;
  color: white;
  font-size: 36px;
  border: none;
  cursor: pointer;
  text-align: center;
  z-index: 9999;
}

.album-below-slider {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
}

.album-slider-image {
  outline: none;
  max-width: 80px;
  height: auto;
}

.album-post-slider {
  position: relative;
  height: fit-content;
}

.next-default {
  position: absolute;
  top: 40%;
  right: 2px;
  font-size: 22px;
  color: #b9912c;
  cursor: pointer;
}

.slick-slide {
  float: right;
}

.prev-default {
  position: absolute;
  top: 40%;
  left: 2px;
  font-size: 22px;
  color: #b9912c;
  cursor: pointer;
}

.album-post-wrapper {
  width: 94%;
  height: 65px;
  margin: 0px auto;
  overflow: hidden;
  padding: 10px 0px 10px 0px;
}


.album-post-wrapper .slick-dots {
  position: absolute;
  bottom: 70px;
  display: block;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
  margin-top: 20px;
}

.album-post-wrapper .slick-dots {
  display: contents;
  margin: 0 5px;
}

.album-post-wrapper .d-flex {
  display: inline-flex;

}

.album-post-wrapper .slick-dots li button {
  border: 1px solid black;
  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 20px;
  border-radius: 50%;
  background-color: transparent;
  text-indent: -9999px;
}

.album-post-wrapper .slick-dots li.slick-active button {
  background-color: black;
}

.main-search-section {
  background: #ffffff;
  border-radius: 15px;
  border-color: #b9912c;
  width: 490px;
  height: 420px;
  border-style: solid;
  margin-bottom: 100px;
  position: relative;
}

.top-image-close {
  position: relative;

}

.btn-f {
  position: absolute;
  color: #a07814;
  font-size: 30px;
  margin-bottom: 5px;
  font-weight: bold;
  top: 0;
  right: 7px;
}

.btn-center {
  position: absolute;
  bottom: 0;
  right: 20px;

}

.progressbar {
  counter-reset: step;
  padding-top: 24px;
  padding-bottom: 60px;
}

.inside-content {
  margin-bottom: 100px;
}

.progressbar li {
  list-style-type: none;
  width: 16%;
  float: left;
  position: relative;
  text-align: center;
}

.float-button {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.progressbar li:before {
  content: '';
  width: 10px;
  height: 10px;
  border: 1px solid black;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: black;
  position: relative;
  z-index: 999999;
}

.progressbar li:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: black;
  top: 4px;
  left: -50%;
  z-index: 999888;
}

.progressbar li:first-child:after {
  content: none;

}

.progressbar li.active {
  font-weight: bold;
  font-size: 12px;
}

.progressbar li.active:before {
  width: 20px;
  height: 20px;
  background-color: #b9912c;
  border-color: #b9912c;
  top: -5px;
}

.img-f {
  width: 100%;
  border-radius: 13px;
}

.fitcontentbtn {
  width: fit-content;
  text-align: center;
  display: inline;
  border-radius: 5px;
  height: fit-content;
  color: black;
  border: 2px solid #b9912c;
  padding: 1px;
  background: #f8f9fa;
}


@media (max-width: 750px) {
  .main-search-section {
    width: 90%;
  }
  .font-xs {
    font-size: 8px;
  }
  h6 {
    font-size: 12px;
  }
  h4 {
    font-size: 12px;
  }
  .progressbar li.active {
    font-size: 12px;
  }
}


.price_filter {
  padding-bottom: 8px;
}

.price_slider_amount {
  overflow: hidden;
}

.price_slider_amount > input {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  box-shadow: none;
  color: #444444;
  float: left;
  font-size: 14px;
  font-weight: 300;
  height: 20px;
  line-height: 20px;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  width: 50%;
}

.price_slider_amount > input:first-child {
  padding-right: 5px;
  text-align: center;
}

.price_slider_amount > input#amount {
}

.ui-widget-content {
  background: #606060 none repeat scroll 0 0;
  border: medium none;
  color: #222;
  height: 8px;
  border-radius: 0;
}


.nivo-directionNav a:hover,
.nivo-controlNav a.active,
.slider-button:hover,
.mark, mark,
#scrollUp:hover,
.bg-theme,
.call-to-bg::before,
.ui-slider .ui-slider-range,
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default,
.flat-link > a:hover,
.button-1,
.pagination-list > li a:hover,
.submit-btn-1,
.widget-search button:hover,
.blog-details-title-time::before,
.blog-quote::before,
.line-bottom::before,
.go-home:hover,
.dots-right-btm .slick-dots li.slick-active button,
.subscribe button,
.footer-contact button:hover,
.blog-item:hover .post-title-time::before,
.for-sale {
  background: #b9912c none repeat scroll 0 0;
}

.slider-button:hover,
.flat-link > a:hover,
.button-1,
.brand-item:hover,
.go-home:hover {
  border: 1px solid #b9912c;
}

.find-home-item input[type="text"]:focus,
.find-home-item input[type="email"]:focus,
.find-home-item input[type="password"]:focus,
.find-home-item textarea:focus {
  border: 1px solid #b9912c;
}

.single-agent:hover {
  border-color: #b9912c;
}

.blog-item:hover .blog-info,
.blog-item:hover .blog-info a {
  border-color: #b9912c;
  color: #b9912c;
}

.container-img {

  text-align: center;
  color: white;

}

.container-img img {
  border: 4px solid #b9912c;
  outline: none;
  width: 100%;
  height: 100%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;

}

/* Bottom left text */
.bottom-left-note-img {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.top-left-note-img {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */
.top-right-note-img {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.bottom-right-note-img {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered-note-img {
  position: absolute;
  font-weight: bold;
  font-size: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.blog-banner, .contact-banner, .about-banner {
  position: relative;
}

.blog-banner:before, .contact-banner:before, .about-banner:before {
  position: absolute;
  content: "";
  height: 100%;
  background: #000;
  opacity: 0.3;
  width: 100%;
  transition: .3s;
}

.blog-banner:hover:before, .contact-banner:hover:before, .about-banner:hover:before {
  opacity: 0.7;
}

.blog-banner a, .contact-banner a {
}

.blog-banner img, .contact-banner img {
  width: 100%;
}

.blog-banner-text, .contact-banner-text, .about-banner-text {
  display: inline-block;
  left: 35px;
  line-height: 12px;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  cursor: pointer;
}

.blog-banner-text h2, .contact-banner-text h2, .about-banner-text h2 {
  color: #fff;
  font-size: 30px;
  letter-spacing: 2px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.blog-banner-text h2 a {
  color: #fff;
}

.blog-banner-text h2 a:hover, .about-banner-text h2 a:hover {
  color: #b9912c;
}

.blog-banner-text.color-black a, .blog-banner-text.color-black h4, .about-banner-text h2 a {
  color: #fff;
}

h4.home-posted {
  color: #fff;
  font-size: 16px;
}

h4.home-posted a {
  color: #fff;
}

h4.home-posted a:hover {
  color: #b9912c;
}

.transparent-button-on-image {
  color: white;
  border: 2px solid #b9912c;
  border-radius: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  font-weight: 700;
}

.article-div {
  margin: 20px auto;
  padding: 0px;
  width: 100%;
}

.article-div img {
  width: inherit;
}

.article-div p {
  padding: 10px;

  color: #b9912c;
}

.contactsForm {
  margin: 30px auto;
  width: 100%;

}

.input-iletisim {
  border-radius: 7px;
  border: 3px solid #b9912c;
  color: black;
  background: #f8f9fa;
  margin: 10px auto;
  width: 33%;
  text-indent: 8px;
}

.input-mesaj {
  text-indent: 10px;
  border-radius: 7px;
  border: 3px solid #b9912c;
  margin: 0 auto;
  width: 100%;
  color: black;
  background: #f8f9fa;
}

.input-btn-send {
  margin-top: 5px;
  border: 3px solid #b9912c;
  border-radius: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 16px;
  padding-right: 22px;
  padding-left: 22px;


}

.input-btn-send:hover {
  border: 3px solid black;
  background: #000000;
  color: white;
}

.mtb-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}


.login-form {
  background: #fff;
  width: 90%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.2);
}

.login-form h3 {
  padding: 35px 35px 0 35px;
  font-weight: 300;
}

.login-form .content {
  padding: 35px;
  text-align: center;
}

.input-field-flex {
  padding: 12px 5px;
  text-align: left;

}

.input-field {
  padding: 12px 5px;
}

.input-field input {
  font-size: 16px;
  display: block;
  text-indent: 10px;
  width: 100%;
  padding: 10px 1px;
  border: 0;
  border-bottom: 1px solid #747474;
  outline: none;
  transition: all .2s;
}

.input-field2 input {
  font-size: 16px;
  display: block;
  text-indent: 10px;
  width: 100%;
  padding: 10px 1px;
  border: 0;
  border-bottom: 1px solid #ffffff;
  outline: none;
  transition: all .2s;
}

.input-field input:focus {
  border-color: #b9912c;
}

.input-field textarea {
  font-size: 16px;
  display: block;
  text-indent: 10px;
  width: 100%;
  padding: 10px 1px;
  border: 1px solid #747474;
  outline: none;
  transition: all .2s;
}


.input-field textarea:focus {
  border: 1px solid #b9912c;

}


.login-form a.link {
  text-decoration: none;
  color: #747474;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 20px;
}

.login-form a.link:hover {
  color: #b9912c;
}

.login-form .action {
  display: flex;
  flex-direction: row;
}

.login-form .action-2 {
  display: inherit;
  flex-direction: row;
}

.login-form button {
  width: 100%;
  border: none;
  padding: 18px;
  font-family: 'Rubik', sans-serif;
  cursor: pointer;
  text-transform: uppercase;
  background: #e8e9ec;
  color: #777;

  letter-spacing: 0.2px;
  outline: 0;
  transition: all .3s;
}

.action button:hover {
  background: black;
  color: white;
}

.btn-looking {
  width: 100%;
  text-align: center;
  border: none;
  padding: 18px;
  font-family: 'Rubik', sans-serif;
  cursor: pointer;
  text-transform: uppercase;
  background: #e8e9ec;
  color: #777;
  letter-spacing: 0.2px;
  outline: 0;
  transition: all .3s;
}

.btn-looking:hover {
  background: black;
  color: white;
}

.btn-looking-bordered {
  margin-top: 5px;
  border: 3px solid #b9912c;
  border-radius: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 16px;
  padding-right: 22px;
  padding-left: 22px;
}

.btn-looking-bordered:hover {
  background: black;
  color: white;
}

@media (max-width: 768px) {
  .login-form {
    width: 90%;
  }
}

.login-side {
  margin: 20px auto 0;
  min-height: 290px;
}

.login-side-pro {
  padding-top: 90px;
  text-align: center;
}

.login-form dd {
  width: 100%;
}

.login-form h6 {
  font-weight: bold;
  margin-top: 20px;
  text-align: left;
}

.login-form input[type="file"] {

  border-radius: 5px;
  border: 2px solid #b9912c;
  padding-bottom: 35px;
  background: #f8f9fa;
}

.display-none {
  display: none;
}

.side-menu-icons {
  border-right: 1px solid black;
  padding-right: 10px;
  margin-right: 10px
}

.sidebar-item-selection span {
  padding-left: 5px;
}

.nivo-directionNav a {
  border: 2px solid #b9912c;
  color: #333333;
  cursor: pointer;
  font-size: 20px;
  padding: 5px 5px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 55%;
  border-radius: 0%;
  z-index: 9;
}

.nivo-prevNav {
  left: 10px;
}

.nivo-nextNav {
  right: 10px;
}

.nivo-directionNav a:hover {
  background: #b9912c none repeat scroll 0 0;
  border: 2px solid #b9912c;
  color: #fff;
}

.borderinputfile {
  border-radius: 7px;
  border: 3px solid #b9912c;
  color: black;
  background: #f8f9fa;
  margin: 10px auto;
}

.navdiv {
  position: relative;
  width: 100%;

}

.navdiv ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navdiv ul {
  /* Sub Menu */
}

.navdiv ul a {

  text-decoration: none;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -ms-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
}

.navdiv ul li a:hover {

  color: #b9912c;
}

.navdiv ul li a .fa {
  width: 16px;
  text-align: center;
  margin-right: 5px;
  float: right;
}

.navdiv ul ul {

}

.navdiv ul li ul li a {
  padding-left: 10px;
  border-left: 4px solid transparent;
}

.navdiv ul li ul li a:hover {

  border-left: 4px solid #b9912c;
}

.drag-area-body {
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //min-height: 65vh;
}

.drag-area {
  border: 2px dashed black;
  height: 80%;
  width: 70%;
  padding-bottom: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag-area.active {
  border: 2px solid black;
}

.drag-area .icon {
  font-size: 100px;
  color: #b9912c;
}

.drag-area header {
  font-size: 18px;
  font-weight: 700;
  color: #b9912c;
}

.drag-area span {
  font-size: 12px;
  font-weight: 400;
  color: black;
  margin: 10px 0 15px 0;
}

.drag-area button {
  padding: 10px 25px;
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #b9912c;
  outline: none;
  background: #b9912c;
  color: #FFFFFF;
  border-radius: 5px;
  cursor: pointer;
}

.drag-area img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.pull-right {
  float: right !important;
}

.badge {
  background: #e9ecef;
  padding: 3px 6px;
}

.block-title {
  margin: 0px 0px 20px;
  padding: 20px;
  background-color: #f9fafc;
  border-bottom: 2px solid #eaedf1;
}

.block.full {
  padding: 10px 10px;
}

.block {

  background-color: #ffffff;
  border: 1px solid #dbe1e8;
}

.btn-xs, .btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-default {
  background-color: #f1f3f6;
  border-color: #dbe1e8;
  color: #394263;
}

.btn-xs, .btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.label-success {
  background-color: #27ae60;
}

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .5em;

}

.label, .badge {
  font-weight: normal;
  font-size: 90%;
}

.label-danger {
  background-color: #e74c3c;
}

.my-img-fluid {
  width: 100%;
  height: 120px;
  object-fit: cover;
}

.same-style {
  margin-left: 16px;
}

.same-style:first-child {
  margin-left: 0px;
}

.same-style button,
.same-style a {
  background-color: transparent;
  border: medium none;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}

.same-style button:hover,
.same-style a:hover {
  color: #b9912c;
}

.header-cart button span {
  font-size: 12px;
  position: absolute;
  right: -6px;
  top: 2px;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #d0a331;
  color: #fff;
  text-align: center;
  line-height: 20px;
  border-radius: 50px;
}

/* header cart */

.sidebar-cart {
  background: #ffffff none repeat scroll 0 0;
  color: #353535;
  min-height: 100vh;
  position: fixed;
  right: -480px;
  top: 0;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  width: 480px;
  z-index: 9999;
}

.sidebar-trigger {
  font-size: 12px;
}

.auction {
  color: white;
  font-weight: bold;
  font-size: 22px
}

.font-m {
  font-size: 16px;
}

.down-radius {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.table-active-hover {
  cursor: pointer;
}

.table-active-hover:hover {
  //opacity: 0.6;
  //color: #b9912c;
  background-color: #e9ecef;
}

.fadestyle {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.invalid-feedback {
  display: block;
  font-weight: 500;
}

.coloronhover:hover {
  color: #b9912c;
}

.overflowing {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 13px;
  min-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scroll-map-view {
  height: calc(100vh - 122px);
  overflow-y: scroll;
  overflow-x: hidden;
}

#jvlabelWrap{right:80px!important}
#jivo_close_button{right: 80px!important;}
#jcont{

  --right: 80px !important;

  --labelright: 80px !important;
}
.uploadfilecontainer {
  background-image: url("/assets/img2/add.png");
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center;
  height: 200px;
  width: 80%;
  margin: 20px auto;
  border: 2px dashed #b9912c;
  border-radius: 10px;
}

.uploadfilecontainer:hover {
  cursor: pointer;
  background-color: #e9ecef !important;
  opacity: 0.8;
}
.multiselect-dropdown .dropdown-btn {
  display: inline-block;
  border: 2px solid #b9912c !important;
  width: 100%;
  padding: 6px 10px !important;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1 !important;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  background-color: #ffffff !important;
  border-radius: 5px !important;
}

.multiselect-dropdown .dropdown-btn  .dropdown-multiselect__caret:before {
  position: relative;
  right: 0;
  top: 65%;
  color: black !important;
  margin-top: 4px;
  width: 30px !important;
  border-style: solid;
  border-width: 8px 8px 0;
  border-color: black  transparent !important;
  content: "";
}
.H_ib_body {
  background: #000;
  width: 180px !important;
  position: absolute;
  bottom: .5em;
  padding: 0 1.2em 0 0;
  border-radius: .2em;
  margin-right: -1em;
  right: 0;
  opacity: 0.8 !important;
}
.H_ib_content {
  min-width: 6em;
  margin: .2em 0;
  padding: 0 .5em !important;
  font-size: 12px !important;

}
