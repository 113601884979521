.form-check,
.form-check-radio {
  padding: 0;
    margin-bottom: 12px;
    position: relative;
}

.form-check{
    .form-check-label{
        display: inline-block;
        position: relative;
        cursor: pointer;
        padding-left: 35px;
        line-height: 20px;
        margin-bottom: 0;
        color: black;
        font-weight: bold;
    }

    .form-check-sign::before,
    .form-check-sign::after{
        content: " ";
        display: inline-block;
        position: absolute;
        width: 20px;
        height: 20px;
        left: 0;
        cursor: pointer;
        border-radius: 3px;
        top: 0;
        background-color: #ffffff;
        border: 2px solid #b9912c;
        @include transition-opacity(0.3s, linear);
    }
    .form-check-sign::after{
        font-family: 'FontAwesome';
        content: "\f005";
        top: 0px;
        text-align: center;
        font-size: 14px;
        opacity: 0;
        color: #EFE;
        border: 0;
        background-color: inherit;
    }
    &.disabled{
        .form-check-label{
            color: $dark-gray;
            opacity: .5;
            cursor: not-allowed;
        }
    }

}

.form-check.disabled .form-check-label,
.form-check.disabled .form-check-label {

}

.form-check input[type="checkbox"],
.form-check-radio input[type="radio"]{
    opacity: 0;
    position: absolute;
    visibility: hidden;
}
.form-check input[type="checkbox"]:checked + .form-check-sign::after{
    opacity: 1;
}

.form-control input[type="checkbox"]:disabled + .form-check-sign::before,
.checkbox input[type="checkbox"]:disabled + .form-check-sign::after{
    cursor: not-allowed;
}

.form-check .form-check-label input[type="checkbox"]:disabled + .form-check-sign,
.form-check-radio input[type="radio"]:disabled + .form-check-sign{
    pointer-events: none !important;
}

.form-check-radio{
    .form-check-label{
        padding-left: 2rem;

        .form-check-sign::after{

        }
    }
    &.disabled{
        .form-check-label{
            color: $dark-gray;
            opacity: .5;
            cursor: not-allowed;
        }
    }
}

.form-check-radio .form-check-sign::before{
    font-family: 'FontAwesome';
    content: "\f10c";
    font-size: 22px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    position: absolute;
    opacity: .50;
    left: 5px;
    top: -5px;
}

.form-check-label input[type="checkbox"]:checked + .form-check-sign:before{
    background-color: #66615B;
}

.form-check-radio input[type="radio"] + .form-check-sign:after,
.form-check-radio input[type="radio"] {
    opacity: 0;
    display: block;
    content: "";
    @include transition-opacity(1s, linear);
}

.form-check-radio input[type="radio"]:checked + .form-check-sign::after {
    font-family: 'FontAwesome';
    content: "\f192";
    top: -5px;
    position: absolute;
    left: 5px;
    opacity: 1;
    font-size: 22px;
}

.form-check-radio input[type="radio"]:checked + .form-check-sign::after{
    opacity: 1;
}


.form-check-radio input[type="radio"]:disabled + .form-check-sign::before,
.form-check-radio input[type="radio"]:disabled + .form-check-sign::after {
    color: $dark-gray;
}

// .form-check:not(.disabled):hover{
//     .form-check-sign:after{
//         opacity: .50;
//     }
// }
// .form-check-radio:not(.disabled){
//     .form-check-label:hover{
//         .form-check-sign::after{
//             font-family: 'FontAwesome';
//             content: "\f192";
//             position: absolute;
//             font-size: 22px;
//             opacity: .50;
//             top: -5px;
//             left: 5px;
//         }
//
//         input[type="radio"]:checked + .form-check-sign::after{
//             opacity: 1;
//         }
//         .form-check-sign::before{
//             opacity: 0;
//         }
//     }
// }
